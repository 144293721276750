import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";
import likert from "./questions.json";
import "./LikertScale.css"; // Optional: Include CSS for styling
import API from "./Api";
import AppContext from "./Context";

const LikertScale = ({ question, options, onChange }) => {
	const [selectedOption, setSelectedOption] = useState("a3");
	const handleOptionChange = (qid, oid) => {
		setSelectedOption(oid);
		if (onChange) {
			onChange(oid);
		}
	};

	return (
		<div className="likert-scale">
			<p className="likert-question">{question.question}</p>
			<div className="likert-options">
				{options.map((option) => (
					<label key={option.id} className="likert-option">
						<input
							type="radio"
							value={option.id}
							checked={selectedOption === option.id}
							onChange={(e) => handleOptionChange(question.id, option.id)}
						/>
						<span>{option.option}</span>
					</label>
				))}
			</div>
		</div>
	);
};

export const Questions = () => {
	const { user, finishLikert } = useContext(AppContext);
	const [answers, setAnswers] = useState();

	useEffect(() => {
		const initialAnswers = likert.questions.reduce((acc, question) => {
			acc[question.id] = "a3";
			return acc;
		}, {});
		setAnswers(initialAnswers);
	}, []);

	const handleAnswerChange = (id, answer) => {
		setAnswers((prevAnswers) => ({
			...prevAnswers,
			[id]: answer,
		}));
	};

	const handleSubmit = async () => {
		try {
			await API.postLikert(user, answers);
			finishLikert(true);
		} catch (error) {
			console.error("Error:", error);
			return;
		}
	};

	return (
		<div className="survey-container">
			<h1>Survey</h1>
			{likert.questions.map((question) => (
				<LikertScale
					key={question.id}
					question={question}
					options={likert.options}
					onChange={(answer) => handleAnswerChange(question.id, answer)}
				/>
			))}
			<button type="button" className="likert-button" onClick={handleSubmit}>
				Gönder
			</button>
		</div>
	);
};
