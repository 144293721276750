import React, { createContext, useState, useEffect } from "react";
import data from "./data";

// Create a context with 'light' as the default value
const AppContext = createContext({});

export const AppProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [questions, setQuestions] = useState(data);
	const [finished, setFinished] = useState(false);
	const [likertDone, setLikertDone] = useState(false);

	useEffect(() => {
		const user = localStorage.getItem("user");
		if (user) {
			setUser(user);
		}

		const finished = localStorage.getItem("finished");
		if (finished === "true") {
			setFinished(true);
		}

		const likertDone = localStorage.getItem("likertDone");
		if (likertDone === "true") {
			setLikertDone(true);
		}
	}, []);

	const login = (name) => {
		localStorage.setItem("user", name);
		setUser(name);
	};

	const finish = () => {
		localStorage.setItem("finished", true);
		setFinished(true);
	};

	const finishLikert = () => {
		localStorage.setItem("likertDone", true);
		setLikertDone(true);
	};

	const reset = () => {
		localStorage.removeItem("finished");
		localStorage.removeItem("likertDone");
		setFinished(false);
		setLikertDone(false);
	};

	return (
		<AppContext.Provider
			value={{
				login,
				user,
				setUser,
				questions,
				finished,
				finish,
				likertDone,
				finishLikert,
				reset,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export default AppContext;
