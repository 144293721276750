import "./App.css";
import { AppProvider } from "./Context";
import { Form } from "./Form";

const Footer = () => {
	const footerStyle = {
		backgroundColor: "#f1f1f1",
		padding: "20px",
		textAlign: "center",
		borderTop: "1px solid #ccc",
		marginTop: "50px",
		fontFamily: "Arial, sans-serif",
	};

	const trademarkStyle = {
		fontSize: "14px",
		color: "#333",
		marginBottom: "10px",
	};

	const linkStyle = {
		color: "#0066cc",
		textDecoration: "none",
		margin: "0 5px",
	};

	return (
		<footer style={footerStyle}>
			<div style={trademarkStyle}>NaraXR&copy; 2024</div>
			<div>
				<a
					href="https://www.nara.com.tr"
					style={linkStyle}
					target="_blank"
					rel="noopener noreferrer"
				>
					www.nara.com.tr
				</a>
				|
				<a href="mailto:info@nara.com.tr" style={linkStyle}>
					info@nara.com.tr
				</a>
			</div>
		</footer>
	);
};

function App() {
	return (
		<div className="App">
			<AppProvider>
				<Form />
			</AppProvider>
			<Footer />
		</div>
	);
}

export default App;
