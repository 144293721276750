import { useAnimations, useGLTF } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { Suspense, useEffect, useRef } from "react";

export const AvatarPanel = React.memo(({ animation }) => {
	const group = useRef();
	const { scene, animations } = useGLTF("/anim4.glb");
	const { ref, mixer, names, actions, clips } = useAnimations(
		animations,
		group,
	);
	console.log(animation);
	console.log(actions);
	useEffect(() => {
		if (actions[animation]) {
			actions[animation].reset().fadeIn(0.5).play();
		}

		// Optionally clean up the animation when the component unmounts
		return () => actions[animation]?.fadeOut(0.5);
	}, [actions, animation]);

	return (
		<group ref={group} dispose={null}>
			<primitive object={scene} />
		</group>
	);
});
