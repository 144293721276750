import {
	Environment,
	OrbitControls,
	useAnimations,
	useGLTF,
} from "@react-three/drei";
import { Canvas, useThree } from "@react-three/fiber";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Suspense } from "react";
import API from "./Api";
import { AvatarPanel } from "./Avatar";
import AppContext from "./Context";
import { Questions } from "./Likert";

const animationSrc = "/anim.glb";
export const Form = () => {
	const { user, reset, finished, finish, finishLikert, likertDone } =
		useContext(AppContext);
	const [index, setIndex] = useState(0);

	useEffect(() => {
		localStorage.getItem("index") &&
			setIndex(Number.parseInt(localStorage.getItem("index")));
	}, []);

	if (finished && !likertDone) {
		return <Questions />;
	}

	if (finished && likertDone) {
		return <ThankYouMessage onRetakeTest={reset} />;
	}

	if (user) {
		return <Test startIndex={index} />;
	}

	return <Login />;
};

export const Test = ({ startIndex }) => {
	const inputRef = useRef(null);
	const { user, questions, finish } = useContext(AppContext);
	const [answer, setAnswer] = useState("");
	const [index, setIndex] = useState(startIndex || 0);
	const [startTime, setStartTime] = useState(null);
	const [showVideo, setShowVideo] = useState(false);

	const total = questions.length;
	const question = questions[index];


	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		setStartTime(performance.now());
	}, [index]);

	useEffect(() => {
		localStorage.setItem("index", index);
	}, [index]);

	const nextQuestion = () => {
		if (index === questions.length - 1) {
			return;
		}

		setIndex(index + 1);
	};

	const prevQuestion = () => {
		if (index === 0) {
			return;
		}

		setIndex(index - 1);
	};

	const sendAnswer = async () => {
		const endTime = performance.now();
		const duration = endTime - startTime;
		const { label } = question;

		try {
			await API.postAnwer(user, label, answer, duration);
		} catch (error) {
			console.error("Error:", error);
			return;
		}

		setAnswer("");
		if (index === questions.length - 1) {
			finish(true);
		} else {
			nextQuestion();
		}
	};

	const containerStyle = {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "10px 20px",
		backgroundColor: "#f5f5f5",
		borderRadius: "8px",
		marginBottom: "20px",
	};

	const leftStyle = {
		flex: 1,
		color: "#333",
		fontSize: "18px",
	};

	const centerStyle = {
		flex: 1,
		display: "flex",
		justifyContent: "center",
		gap: "10px",
	};

	const inputStyle = {
		padding: "8px 12px",
		border: "1px solid #ccc",
		borderRadius: "4px",
		fontSize: "16px",
		boxSizing: "border-box",
		width: "100%",
	};

	const buttonStyle = {
		padding: "8px 16px",
		backgroundColor: "#4CAF50",
		color: "#fff",
		border: "none",
		borderRadius: "4px",
		fontSize: "16px",
		cursor: "pointer",
		height: "fit-content"
	};

	const buttonDisabledStyle = {
		padding: "8px 16px",
		backgroundColor: "#ccc",
		color: "#777",
		border: "none",
		borderRadius: "4px",
		fontSize: "16px",
		cursor: "not-allowed",
		height: "fit-content"
	};

	const rightStyle = {
		flex: 1,
		textAlign: "right",
		color: "#333",
		fontSize: "18px",
	};

	const isButtonDisabled = answer === "";

	return (
		<>
			<div style={containerStyle}>
				<div style={leftStyle}>
					<span>Merhaba, {user}!</span>
				</div>
				<div style={centerStyle}>
					<div style={{
						display: "flex",
						flexDirection: "column",
						flex: 1,
						alignItems: "stretch",
						justifyContent: "center",
						gap: 10,
					}}>
						<div style={{
							flex: 1,
						}}>
							<input
								type="text"
								placeholder="Kelime"
								ref={inputRef}
								style={inputStyle}
								value={answer}
								// biome-ignore lint/a11y/noAutofocus: <explanation>
								autoFocus
								onChange={(e) => setAnswer(e.target.value)}
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										sendAnswer();
									}
								}}
							/>
						</div>
						<div style={{
							flex: 1,
							display: "flex",
							justifyContent: "space-between"
						}}>
							<div><span style={{textAlign: "center", fontSize: 18, color: "rgb(51, 51, 51)"}}><span style={{fontWeight: "bold"}}>Kategori: </span>{question.cat}</span></div>
							<div onClick={() => setShowVideo(true)}><span>Video</span></div>
						</div>
					</div>
					<button
						type="button"
						style={isButtonDisabled ? buttonDisabledStyle : buttonStyle}
						disabled={isButtonDisabled}
						onClick={sendAnswer}
					>
						{index === questions.length - 1 ? "Bitir" : "Gönder"}
					</button>
				</div>
				<div style={rightStyle}>
					<span>{`${index + 1}/${total}`}</span>
				</div>
			</div>
			<Canvas
				camera={{ position: [0, 1.5, 1] }}
				onMouseUp={() => inputRef.current.focus()}
			>
				<Environment preset="sunset" environmentIntensity={1} />
				<OrbitControls
					target={[0, 1.2, 0]}
					// minPolarAngle={1.4}
					// maxPolarAngle={1.4}
					enablePan={false}
				/>
				<Suspense fallback={null}>
					<AvatarPanel animation={question.anim} />
				</Suspense>
			</Canvas>
			{showVideo && <Video url={question.vid} onClose={() => setShowVideo(false)}/>}
		</>
	);
};

const Video = ({url, onClose}) => {
	console.log(url)
	return <div style={{
		height: "100vh",
		width: "100vw",
		position: "absolute",
		zIndex: 1000,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "rgba(0, 0, 0, 0.7)",
		flexDirection: "column",
		gap: 20,
	}} onClick={(e) => {
		if (e.target === e.currentTarget) {
			onClose();
		}}}>
				<video autoPlay loop muted src={url} style={{width: "50%", height: "auto"}} />
				<button style={{padding: "8px 16px", fontSize: 16} }onClick={() => onClose()}> Kapat </button>
			</div>;
}

function Model({ url, animUrl, position }) {
	const { scene: targetScene } = useGLTF(url);
	const { scene: sourceScene, animations } = useGLTF(animUrl);
	const { ref, mixer, names, actions, clips } = useAnimations(animations);

	const cleaned = names.map((name) => {
		const clenanedName = name.replace(/^0+/, "");
		const result = clenanedName.split("_")[0];
		return result;
	});

	console.log(cleaned);

	// useEffect(() => {
	// 	if (sourceScene === null || targetScene === null || actions === null) {
	// 		return;
	// 	}
	// 	let sourceSkeleton, targetSkeleton;

	// 	sourceScene.traverse((object) => {
	// 		if (object.isSkinnedMesh) {
	// 			sourceSkeleton = object.skeleton;
	// 		}
	// 	});

	// 	targetScene.traverse((object) => {
	// 		if (object.isSkinnedMesh) {
	// 			targetSkeleton = object.skeleton;
	// 		}
	// 	});

	// 	const opts = {
	// 		hip: "Hips",
	// 		names: {
	// 			Hips: "CC_Base_Hip",
	// 			Spine: "CC_Base_Waist",
	// 			Spine1: "CC_Base_Spine01",
	// 			Spine2: "CC_Base_Spine02",
	// 			RightShoulder: "CC_Base_R_Clavicle",
	// 			RightArm: "CC_Base_R_Upperarm",
	// 			RightForeArm: "CC_Base_R_Forearm",
	// 			RightHand: "CC_Base_R_Hand",
	// 			RightHandThumb1: "CC_Base_R_Thumb1",
	// 			RightHandThumb2: "CC_Base_R_Thumb2",
	// 			RightHandThumb3: "CC_Base_R_Thumb3",
	// 			RightHandIndex1: "CC_Base_R_Index1",
	// 			RightHandIndex2: "CC_Base_R_Index2",
	// 			RightHandIndex3: "CC_Base_R_Index3",
	// 			RightHandMiddle1: "CC_Base_R_Mid1",
	// 			RightHandMiddle2: "CC_Base_R_Mid2",
	// 			RightHandMiddle3: "CC_Base_R_Mid3",
	// 			RightHandRing1: "CC_Base_R_Ring1",
	// 			RightHandRing2: "CC_Base_R_Ring2",
	// 			RightHandRing3: "CC_Base_R_Ring3",
	// 			RightHandPinky1: "CC_Base_R_Pinky1",
	// 			RightHandPinky2: "CC_Base_R_Pinky2",
	// 			RightHandPinky3: "CC_Base_R_Pinky3",
	// 			LeftShoulder: "CC_Base_L_Clavicle",
	// 			LeftArm: "CC_Base_L_Upperarm",
	// 			LeftForeArm: "CC_Base_L_Forearm",
	// 			LeftHand: "CC_Base_L_Hand",
	// 			LeftHandThumb1: "CC_Base_L_Thumb1",
	// 			LeftHandThumb2: "CC_Base_L_Thumb2",
	// 			LeftHandThumb3: "CC_Base_L_Thumb3",
	// 			LeftHandIndex1: "CC_Base_L_Index1",
	// 			LeftHandIndex2: "CC_Base_L_Index2",
	// 			LeftHandIndex3: "CC_Base_L_Index3",
	// 			LeftHandMiddle1: "CC_Base_L_Mid1",
	// 			LeftHandMiddle2: "CC_Base_L_Mid2",
	// 			LeftHandMiddle3: "CC_Base_L_Mid3",
	// 			LeftHandRing1: "CC_Base_L_Ring1",
	// 			LeftHandRing2: "CC_Base_L_Ring2",
	// 			LeftHandRing3: "CC_Base_L_Ring3",
	// 			LeftHandPinky1: "CC_Base_L_Pinky1",
	// 			LeftHandPinky2: "CC_Base_L_Pinky2",
	// 			LeftHandPinky3: "CC_Base_L_Pinky3",
	// 			Neck: "CC_Base_NeckTwist01",
	// 			Head: "CC_Base_NeckTwist02",
	// 			HeadTop_End: "CC_Base_Head",
	// 			HeadTop_End_end: "CC_Base_FacialBone",
	// 			RightUpLeg: "CC_Base_R_Thigh",
	// 			RightLeg: "CC_Base_R_Calf",
	// 			RightFoot: "CC_Base_R_Foot",
	// 			RightToeBase: "CC_Base_R_ToeBase",
	// 			RightToe_End: "CC_Base_R_MidToe1",
	// 			LeftUpLeg: "CC_Base_L_Thigh",
	// 			LeftLeg: "CC_Base_L_Calf",
	// 			LeftFoot: "CC_Base_L_Foot",
	// 			LeftToeBase: "CC_Base_L_ToeBase",
	// 			LeftToe_End: "CC_Base_L_MidToe1",
	// 		},
	// 	};

	// 	for (let i = 0; i < sourceSkeleton.bones.length; i++) {
	// 		const sourceBone = sourceSkeleton.bones[i];
	// 	}

	// 	for (let i = 0; i < targetSkeleton.bones.length; i++) {
	// 		const targetBone = targetSkeleton.bones[i];

	// 		if (targetBone.name in opts.names) {
	// 			targetBone.name = opts.names[targetBone.name];
	// 		}
	// 	}

	// 	console.log("source", sourceSkeleton);
	// 	console.log("target", targetSkeleton);
	// 	if (sourceSkeleton && targetSkeleton) {
	// 		// Perform the retargetin

	// 		//SkeletonUtils.retarget(targetSkeleton, sourceSkeleton, opts);
	// 		const actionName = Object.keys(actions)[0]; // Use the first animation in the target model
	// 		const targetAction = actions[actionName];

	// 		// biome-ignore lint/complexity/noForEach: <explanation>
	// 		// targetAction._clip.tracks.forEach((track) => {
	// 		// 	console.log(track);
	// 		// 	if (track.name === "CC_Base_Hip.quaternion") {
	// 		// 		for (let i = 0; i < track.values.length; i += 4) {
	// 		// 			const x = track.values[i];
	// 		// 			const y = track.values[i + 1];
	// 		// 			const z = track.values[i + 2];
	// 		// 			const w = track.values[i + 3];

	// 		// 			// Adjust the quaternion to rotate the animation by 90 degrees around the X-axis
	// 		// 			const q = new THREE.Quaternion(x, y, z, w);
	// 		// 			const adjust = new THREE.Quaternion();
	// 		// 			adjust.setFromAxisAngle(new THREE.Vector3(1, 0, 0), -Math.PI / 2);

	// 		// 			q.premultiply(adjust);

	// 		// 			track.values[i] = q.x;
	// 		// 			track.values[i + 1] = q.y;
	// 		// 			track.values[i + 2] = q.z;
	// 		// 			track.values[i + 3] = q.w;
	// 		// 		}
	// 		// 	}
	// 		// });

	// 		console.log(targetAction);
	// 		targetAction.play();
	// 	}
	// }, [targetScene, sourceScene, actions, mixer]);

	return <primitive ref={ref} object={targetScene} position={position} />;
}

function RetargetedAnimation() {
	console.log("sssss");

	return (
		<Canvas>
			<ambientLight />
			<pointLight position={[10, 10, 10]} />
			<Model url="/anim4.glb" animUrl="/anim4.glb" position={[0, 0, 0]} />
			<OrbitControls />
		</Canvas>
	);
}

export const Login = () => {
	const { login } = useContext(AppContext);
	const [name, setName] = useState("");
	const [surname, setSurname] = useState("");

	const formStyle = {
		maxWidth: "400px",
		padding: "20px",
		border: "1px solid #ccc",
		borderRadius: "8px",
		boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
		backgroundColor: "#f9f9f9",
		fontFamily: "Arial, sans-serif",
	};

	const titleStyle = {
		textAlign: "center",
		marginBottom: "20px",
		color: "#333",
	};

	const descriptionStyle = {
		textAlign: "center",
		marginBottom: "30px",
		color: "#555",
	};

	const inputGroupStyle = {
		marginBottom: "15px",
	};

	const labelStyle = {
		display: "block",
		marginBottom: "5px",
		fontWeight: "bold",
		color: "#333",
	};

	const inputStyle = {
		width: "100%",
		padding: "10px",
		border: "1px solid #ccc",
		borderRadius: "4px",
		fontSize: "16px",
		boxSizing: "border-box",
	};

	const buttonStyle = {
		width: "100%",
		padding: "10px",
		backgroundColor: "#4CAF50",
		color: "#fff",
		border: "none",
		borderRadius: "4px",
		fontSize: "16px",
		cursor: "pointer",
	};

	const buttonHoverStyle = {
		backgroundColor: "#45a049",
	};

	const infoPanelStyle = {
		maxWidth: "400px",
		padding: "20px",
		border: "1px solid #ccc",
		borderRadius: "8px",
		boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
		backgroundColor: "#f9f9f9",
		fontFamily: "Arial, sans-serif",
	};

	const infoTitleStyle = {
		fontSize: "20px",
		marginBottom: "10px",
	};

	const infoTextStyle = {
		fontSize: "16px",
		lineHeight: "1.6",
		marginBottom: "10px",
	};

	const wrapperStyle = {
		display: "flex",
		justifyContent: "center",
		gap: "20px",
		padding: "50px",
	};

	const doLogin = () => {
		if (!name || !surname) {
			alert("Lütfen isim ve soyisim girin.");
			return;
		}

		login(`${name} ${surname}`);
	};

	return (
		<div style={wrapperStyle}>
			<div style={formStyle}>
				<h2 style={titleStyle}>Hoşgeldiniz</h2>
				<p style={descriptionStyle}>
					Bu uygulama Türk İşaret Dili için geliştirdiğimiz avatarlar ve
					animasyonların anlaşılabilirliğini test etmek için tasarlanmıştır.
				</p>
				<form>
					<div style={inputGroupStyle}>
						<label htmlFor="name" style={labelStyle}>
							İsim:
						</label>
						<input
							type="text"
							id="name"
							name="name"
							style={inputStyle}
							required
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
					<div style={inputGroupStyle}>
						<label htmlFor="surname" style={labelStyle}>
							Soyisim:
						</label>
						<input
							type="text"
							id="surname"
							name="surname"
							style={inputStyle}
							required
							value={surname}
							onChange={(e) => setSurname(e.target.value)}
						/>
					</div>
					<button
						type="submit"
						style={{ ...buttonStyle, ":hover": buttonHoverStyle }}
						onClick={(e) => {
							e.preventDefault();
							doLogin();
						}}
					>
						Başlat
					</button>
				</form>
			</div>
			<div style={infoPanelStyle}>
				<h3 style={infoTitleStyle}>Talimatlar</h3>
				<p style={infoTextStyle}>
					Uygulamada, işaret dili kelimeleri yapan bir 3B avatar göreceksiniz.
					Avatarla aşağıdaki şekillerde etkileşime girebilirsiniz:
				</p>
				<ul style={infoTextStyle}>
					<li>
						Yakınlaştırmak ve uzaklaştırmak için fare tekerleğini
						kullanabilirsiniz.
					</li>
					<li>Avatarı döndürmek için sol tıklayıp sürükleyebilirsiniz.</li>
				</ul>
				<p style={infoTextStyle}>
					Avatarın hareketlerini inceleyerek, işaret dili kelimelerini anlamaya
					çalışın. Ardından, kelimeyi yazarak gönder butonuna tıklayın.
				</p>
				<p style={infoTextStyle}>
					Sağ üstte, mevcut soruyu ve toplam soru sayısını görebilirsiniz.
				</p>
			</div>
		</div>
	);
};

const ThankYouMessage = ({ onRetakeTest }) => {
	const containerStyle = {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		padding: "20px",
		backgroundColor: "#f9f9f9",
		borderRadius: "8px",
		boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
		maxWidth: "400px",
		margin: "50px auto",
		textAlign: "center",
	};

	const messageStyle = {
		fontSize: "20px",
		color: "#333",
		marginBottom: "20px",
	};

	const contactStyle = {
		fontSize: "16px",
		color: "#555",
		marginBottom: "30px",
	};

	const buttonStyle = {
		padding: "10px 20px",
		backgroundColor: "#4CAF50",
		color: "#fff",
		border: "none",
		borderRadius: "4px",
		fontSize: "16px",
		cursor: "pointer",
	};

	return (
		<div style={containerStyle}>
			<div style={messageStyle}>
				Yardımlarınızdan dolayı çok teşekkür ederiz!
			</div>
			<div style={contactStyle}>
				Eğer bir sorunuz varsa lütfen bize ulaşın:{" "}
				<a href="mailto:info@nara.com.tr">info@nara.com.tr</a>
			</div>
			<button type="button" style={buttonStyle} onClick={onRetakeTest}>
				Yeniden Başlat
			</button>
		</div>
	);
};

// hip - name of the hip bone in the source skeleton
// names - dictionary that maps from target bone names to source bone names
// preserveHipPosition - if enabled, preserves the Y component of the target hip bone position and zeros its X and Z component
// I assume this was intended to be used alongside some root motion logic but I feel like not useful in its current state.
// preserveMatrix - ?
// The implementation doesn't appear to be preserving matrix data, confused what this is supposed to do
// preservePosition - if enabled, preserves the original .position of target bones except the hip
// Why this is useful? The only bone that I'd expect to have a .position track is the hip, the others only rotate.
// useTargetMatrix - if enabled, this will maintain the impact of target.matrixWorld when calculating target bone positions (defaults to disabled, in which case the inverse of target.matrixWorld will be applied)
// Seems like the default is what you'd always want so that you can retarget even if your target isn't at the origin.
