const URL = "http://localhost:8000";

// biome-ignore lint/complexity/noStaticOnlyClass: <explanation>
export default class API {
	static async postAnwer(name, label, answer, duration) {
		const data = {
			name: name,
			label: label,
			answer: answer,
			duration: duration,
		};

		try {
			const response = await fetch(`${URL}/answer`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const result = await response.json(); // Parse the JSON response
			console.log("Success:", result);
		} catch (error) {
			console.error("Error:", error);
			throw error;
		}
	}

	static async postLikert(name, answers) {
		const data = {
			name: name,
			likerts: [],
		};

		for (const [qid, oid] of Object.entries(answers)) {
			data.likerts.push({
				question: qid,
				option: oid,
			});
		}

		try {
			const response = await fetch(`${URL}/likert`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			});

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const result = await response.json(); // Parse the JSON response
			console.log("Success:", result);
		} catch (error) {
			console.error("Error:", error);
			throw error;
		}
	}
}
